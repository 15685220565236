import React from "react";

import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import Layout from "../components/layout";
import CustomLink from "../components/CustomLink";
import { withPrismicUnpublishedPreview,componentResolverFromMap } from 'gatsby-plugin-prismic-previews';
import BlogDetail from "../templates/blog_detail";
import HomePage from "./index";
import linkResolver from "../../linkResolver";

const PageNotFound = ({ location }) => {
  return (
    <Layout location={location}>
      <LeftColours />
      <RightColours />

      <section className="relative">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            <div className="max-w-3xl mx-auto text-center">
              {/* 404 content */}
              <h1 className="h1 mb-4">Oh, No! You stumbled upon a rarity</h1>
              <div className="mt-8">
                <CustomLink
                  to="/"
                  className="btn text-white bg-purple-600 hover:bg-blue-700"
                >
                  Go back home
                </CustomLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};


export default withPrismicUnpublishedPreview(
  PageNotFound,
  [
    {
      repositoryName: "encord",
      linkResolver,
      componentResolver: {
        blog: BlogDetail,
        home_page:HomePage
      },
    },]);